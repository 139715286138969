import React from 'react';
import telegramIcon from '../../images/TelegramIcon.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  FaEnvelopeSquare,
  FaFacebookSquare,
  FaLinkedin,
  FaTimes,
  FaTwitterSquare,
  FaWhatsappSquare,
} from 'react-icons/fa';

import * as styles from './ShareCard.module.scss';
import { withDataLayer } from '../../utils/utils';

interface ShareCardProps {
  onClose: () => void;
}

const ShareCard = ({ onClose }: ShareCardProps): React.ReactElement => {
  return (
    <div className={styles.shareCardContainer}>
      <FaTimes className={styles.closeIcon} onClick={onClose} />
      <span className={styles.shareCardTitle}>Share with your friends</span>
      <div className={styles.buttonsRow}>
        <FacebookShareButton
          url={window.location.href}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'facebook' });
            });
          }}
        >
          <FaFacebookSquare className={styles.icon} />
          <span className={styles.linkSpan}>Facebook</span>
        </FacebookShareButton>
        <WhatsappShareButton
          url={window.location.href}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'whatsapp' });
            });
          }}
        >
          <FaWhatsappSquare className={styles.icon} />
          <span className={styles.linkSpan}>Whatsapp</span>
        </WhatsappShareButton>
        <TwitterShareButton
          url={window.location.href}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'twitter' });
            });
          }}
        >
          <FaTwitterSquare className={styles.icon} />
          <span className={styles.linkSpan}>Twitter</span>
        </TwitterShareButton>
      </div>
      <div className={styles.buttonsRow}>
        <EmailShareButton
          url={window.location.href}
          className={styles.linkContainer}
          onClick={(_, link) => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'email' });
            });

            // WARNING: don't remove this line, EmailShareButton has it's own onClick
            // that gets overriden by ours so we need to redo it's code here.
            window.location.href = link;
          }}
        >
          <FaEnvelopeSquare className={styles.icon} />
          <span className={styles.linkSpan}>Email</span>
        </EmailShareButton>
        <TelegramShareButton
          url={window.location.href}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'telegram' });
            });
          }}
        >
          <img src={telegramIcon} className={styles.icon} />
          <span className={styles.linkSpan}>Telegram</span>
        </TelegramShareButton>
        <LinkedinShareButton
          url={window.location.href}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'linkedin' });
            });
          }}
        >
          <FaLinkedin className={styles.icon} />
          <span className={styles.linkSpan}>LinkedIn</span>
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default ShareCard;
