import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { clsx } from '../../utils/utils';
import * as styles from './Modal.module.scss';

interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  onClose: React.MouseEventHandler;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
}

const Modal = ({
  children,
  className,
  onClose,
  onMouseDown,
  onMouseUp,
}: ModalProps): React.ReactElement => {
  return (
    <div
      className={styles.container}
      onClick={e => e.stopPropagation()}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {children && <div className={clsx(className, styles.children)}>{children}</div>}
    </div>
  );
};

export default Modal;
