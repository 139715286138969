import React, { forwardRef, useRef } from 'react';
import usePortal from 'react-useportal';
import { clsx } from '../../utils/utils';
import Modal from './Modal';

import * as styles from './ModalOverlay.module.scss';

export interface ModalOverlayProps {
  className?: string;
  childrenClassName?: string;
  onClose: React.MouseEventHandler;
  children: React.ReactNode;
}

const ModalOverlay = forwardRef<HTMLDivElement, ModalOverlayProps>(
  ({ className, childrenClassName, children, onClose }, ref): React.ReactElement => {
    const { Portal } = usePortal();

    const skipNextOverlayClick = useRef(false);

    return (
      <Portal>
        <div
          ref={ref}
          className={clsx(styles.overlay, className)}
          onClick={e => {
            if (skipNextOverlayClick.current) {
              skipNextOverlayClick.current = false;
            } else {
              onClose(e);
            }
          }}
        >
          <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
            <Modal
              onClose={onClose}
              onMouseDown={() => (skipNextOverlayClick.current = true)}
              onMouseUp={() => (skipNextOverlayClick.current = false)}
              className={childrenClassName}
            >
              {children}
            </Modal>
            <div className={styles.modalDecenterSpace} onClick={onClose}></div>
          </div>
        </div>
      </Portal>
    );
  },
);
ModalOverlay.displayName = 'ModalOverlay';

export default ModalOverlay;
