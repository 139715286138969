import React, { useEffect, useRef, useState } from 'react';
import { DEFAULT_CURRENT_YEAR } from '../../constants';
import {
  BirthdayScreen,
  ResultScreen,
  Screen,
  ShareScreen,
  VedicRisingSigns,
} from '../../templates/pages';
import { FormError, useForm, useFormField } from '../../utils/forms';
import { clsx, withDataLayer } from '../../utils/utils';
import ModalOverlay from './ModalOverlay';
import Portal from './Portal';
import * as api from '../../api';
import { unstable_batchedUpdates } from 'react-dom';
import Select, { components } from 'react-select';
import arrowsForm from '../../images/arrowsForm.svg';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import mapPin from '../../images/mapPin.svg';
import * as Sentry from '@sentry/react';
import BlockContent from '@sanity/block-content-to-react';
import InputField from './InputField';
import serializers from '../../serializers';
import ShareCard from './ShareCard';

import * as styles from './PortalForm.module.scss';

export interface PortalFormProps {
  birthdayScreen: BirthdayScreen;
  resultScreen: ResultScreen;
  shareScreen: ShareScreen;
  vedicRisingSigns: VedicRisingSigns;
  className?: string;
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: (state.isSelected && '#BBABED') || (state.isFocused && '#EAE1F7') || '#ffffff',
    cursor: 'pointer',
    color: state.isSelected ? '#434343' : '#4d4d4d',
    padding: 8,
    fontSize: '16px',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor: state.isSelected ? '#BBABED' : '#EAE1F7',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
    color: '#434343',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    div: {
      padding: 4,
    },
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'Open Sans, sans-serif;',
    color: 'rgba(51, 51, 51, 0.4)',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 0 transparent',
    border: 'none',
    borderRadius: 6,
    opacity: state.isDisabled ? '0.8' : '1',
    minHeight: 37,
    height: 37,
    '&:hover': {
      borderColor: state.isFocused ? '#003D41' : '#ffffff',
    },
    '@media (max-width: 969px)': {
      minHeight: 30,
      height: 30,
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    marginTop: 8,
    fontSize: '10px',
    borderRadius: 8,
    overflow: 'hidden',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowsForm} />
    </components.DropdownIndicator>
  );
};

const PortalForm = ({
  birthdayScreen,
  resultScreen,
  shareScreen,
  vedicRisingSigns,
  className,
}: PortalFormProps): React.ReactElement => {
  const [currentScreen, setCurrentScreen] = useState<Screen>('home');
  const [nakshatra, setNakshatra] = useState<null | any>(null);
  const [portalAnimation, setPortalAnimation] = useState<null | 'exit' | 'entry'>(null);
  const [isShareCardVisible, setIsShareCardVisible] = useState(false);
  const [shareCardAnimation, setShareCardAnimation] = useState<null | 'exit' | 'entry'>(null);
  const [cityOfBirthCoords, setCityOfBirthCoords] = useState<{ lat: number; lng: number } | null>(
    null,
  );

  const portalContainerRef = useRef<HTMLDivElement>(null);
  const shareModalRef = useRef<HTMLDivElement>(null);

  const [currentYear, setCurrentYear] = useState<number>(DEFAULT_CURRENT_YEAR);
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  function transitionToNextScreen(screen: Screen) {
    function onPortalAnimationEnd(event) {
      if (event.animationName === styles.rotatePortalExit) {
        unstable_batchedUpdates(() => {
          setPortalAnimation('entry');
          setCurrentScreen(screen);
        });
      } else if (event.animationName === styles.rotatePortalEntry) {
        portalContainerRef.current!.removeEventListener('animationend', onPortalAnimationEnd);
        setPortalAnimation(null);
      }
    }

    portalContainerRef.current!.addEventListener('animationend', onPortalAnimationEnd);
    setPortalAnimation('exit');
  }

  function openShareModal() {
    setShareCardAnimation('entry');
    setIsShareCardVisible(true);
    withDataLayer(dataLayer => {
      dataLayer.push({ event: 'open-share-card' });
    });
  }

  function closeShareModal() {
    function onModalAnimationEnd(event) {
      shareModalRef.current!.removeEventListener('animationend', onModalAnimationEnd);
      setIsShareCardVisible(false);
    }

    shareModalRef.current!.addEventListener('animationend', onModalAnimationEnd);
    setShareCardAnimation('exit');
  }

  const formTranslateFunction = (key: string, defaultText: string) => {
    const text = {
      'form.required_field_error': '*Required Field',
      'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
      'form.network_error': 'Network failed to send your request.',
      'form.unknown_error': 'An unknown error has occurred. Please try again later.',
    }[key as string];

    return text !== undefined ? text : defaultText;
  };

  const form1FieldsByName = {
    year: useFormField<number>(null, ['required']),
    month: useFormField<number>(null, ['required']),
    day: useFormField<number>(null, ['required']),
    hour: useFormField<number>(null, ['required']),
    minutes: useFormField<number>(null, ['required']),
    timePeriod: useFormField<string>('am', ['required']),
    cityOfBirth: useFormField<string>('', [
      'required',
      () => {
        if (!cityOfBirthCoords) {
          return '*You must select a city from the dropdown list';
        }
        return null;
      },
    ]),
  } as const;

  const form2FieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
  } as const;

  const form1 = useForm({
    fieldsByName: form1FieldsByName,
    onSubmit: async () => {
      const year = form1FieldsByName.year.value;
      const month = form1FieldsByName.month.value;
      const day = form1FieldsByName.day.value;
      const hour = form1FieldsByName.hour.value;
      const minutes = form1FieldsByName.minutes.value;
      const timePeriod = form1FieldsByName.timePeriod.value;
      const cityOfBirth = form1FieldsByName.cityOfBirth.value?.trim();

      if (
        typeof year !== 'number' ||
        typeof month !== 'number' ||
        typeof day !== 'number' ||
        typeof hour !== 'number' ||
        typeof minutes !== 'number' ||
        !timePeriod ||
        !cityOfBirth ||
        !cityOfBirthCoords
      ) {
        throw new Error('Called onSubmit without having all data');
      }
      try {
        const nakshatraAndPada = await api.calculateNakshatraAndPada(
          year,
          month,
          day,
          hour,
          minutes,
          timePeriod,
          cityOfBirthCoords,
        );
        withDataLayer(dataLayer => {
          dataLayer.push({
            event: 'form-submit',
            step: 'calculate-nakshatra',
            nakshatra: nakshatraAndPada.nakshatra,
          });
        });
        setNakshatra(nakshatraAndPada.nakshatra);
        transitionToNextScreen('result');
        return true;
      } catch (err) {
        if (err.body?.errorCode === 'INVALID_DATE') {
          throw new FormError(
            err.message,
            'Date is invalid, please correct it and try again',
            true,
          );
        } else {
          throw err;
        }
      }
    },
    translateFunction: formTranslateFunction,
  });

  const form2 = useForm({
    fieldsByName: form2FieldsByName,
    onSubmit: async () => {
      if (process.env.NODE_ENV === 'development') {
        // Code to test form states (should be commented)
        // await wait(1000); // Case loading
        // throw new Error('Got response with status code 400'); // Case unknown error
        // throw new Error('Failed to fetch'); // Case network error
        return true; // Case success
      }
      const name = form2FieldsByName.name.value;
      const email = form2FieldsByName.email.value?.trim();
      const year = form1FieldsByName.year.value;
      const month = form1FieldsByName.month.value;
      const day = form1FieldsByName.day.value;
      const hour = form1FieldsByName.hour.value;
      const minutes = form1FieldsByName.minutes.value;
      const timePeriod = form1FieldsByName.timePeriod.value;
      const cityOfBirth = form1FieldsByName.cityOfBirth.value?.trim();

      if (
        !name ||
        !email ||
        typeof year !== 'number' ||
        typeof month !== 'number' ||
        typeof day !== 'number' ||
        typeof hour !== 'number' ||
        typeof minutes !== 'number' ||
        !timePeriod ||
        !cityOfBirth ||
        !cityOfBirthCoords ||
        !nakshatra
      ) {
        throw new Error('Called onSubmit without having all data');
      }

      await api.addContactToKajabi(
        name,
        email,
        year,
        month,
        day,
        hour,
        minutes,
        timePeriod,
        cityOfBirth,
        cityOfBirthCoords,
        nakshatra,
      );
      // await api.sendNetlifyEmail(
      //   email,
      //   year,
      //   month,
      //   day,
      //   hour,
      //   minutes,
      //   timePeriod,
      //   cityOfBirth,
      //   cityOfBirthCoords,
      //   nakshatra,
      // );
      withDataLayer(dataLayer => {
        dataLayer.push({ event: 'form-submit', step: 'send-email', nakshatra });
      });
      transitionToNextScreen('share');
      return true;
    },

    translateFunction: formTranslateFunction,
  });

  const monthSelectOptions = [
    { value: 1, label: 'Jan' },
    { value: 2, label: 'Feb' },
    { value: 3, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 5, label: 'May' },
    { value: 6, label: 'Jun' },
    { value: 7, label: 'Jul' },
    { value: 8, label: 'Aug' },
    { value: 9, label: 'Sep' },
    { value: 10, label: 'Oct' },
    { value: 11, label: 'Nov' },
    { value: 12, label: 'Dec' },
  ];

  const timePeriodSelectOptions = [
    { value: 'am', label: 'AM' },
    { value: 'pm', label: 'PM' },
  ];

  const rangeOfNumbers = (start: number, end: number): Array<number> =>
    Array(end - start + 1)
      .fill(start)
      .map((e, index) => e + index);

  const years = rangeOfNumbers(1920, currentYear);
  const yearsSelectOptions = years.reverse().map(year => ({ value: year, label: year }));

  const days = rangeOfNumbers(1, 31);
  const daysSelectOptions = days.map(day => ({ value: day, label: day }));

  const hours = rangeOfNumbers(1, 12);
  const hoursSelectOptions = hours.map(hour => ({ value: hour, label: hour }));

  const minutes = rangeOfNumbers(0, 59);
  const formatMinutes = min => (min < 10 ? '0' + min : '' + min);
  const minutesSelectOptions = minutes.map(minute => ({
    value: minute,
    label: formatMinutes(minute),
  }));

  if (!!nakshatra && !(nakshatra in vedicRisingSigns)) {
    throw new Error(
      'Unknown nakshatra. \n' +
        JSON.stringify(
          {
            nakshatra,
            currentScreen,
            vedicRisingSigns: vedicRisingSigns,
          },
          null,
          2,
        ),
    );
  }

  return (
    <div ref={portalContainerRef} className={styles.portalContainer}>
      <Portal
        isPurple={currentScreen === 'result'}
        constantHeight
        title={
          {
            home: birthdayScreen.title,
            result: resultScreen.signSectionTitle,
            share: shareScreen.reportSectionTitle,
          }[currentScreen]
        }
        className={clsx(
          styles.portal,
          portalAnimation === 'exit' && styles.animationExit,
          portalAnimation === 'entry' && styles.animationEntry,
        )}
      >
        {currentScreen === 'home' && (
          <>
            <p className={styles.homeText}>{birthdayScreen.introText}</p>
            <div className={styles.formContainer}>
              <form name="contact" data-netlify="true">
                <div className={styles.inputsContainer}>
                  <span className={styles.groupTitle}>Birth Date</span>
                  <div className={styles.inputGroupContainer}>
                    <div className={styles.inputContainer}>
                      <label
                        className={clsx(styles.label, form1FieldsByName.year.error && styles.error)}
                        htmlFor="year-input"
                      >
                        Year
                      </label>
                      <div className={clsx(styles.selectWrapper)}>
                        <div
                          className={clsx(
                            styles.select,
                            form1FieldsByName.year.error && styles.error,
                          )}
                        >
                          <Select
                            inputId="year-input"
                            name="year"
                            placeholder="1999"
                            styles={customStyles}
                            options={yearsSelectOptions}
                            components={{ DropdownIndicator }}
                            value={
                              yearsSelectOptions.find(
                                option => option.value === form1FieldsByName.year.value,
                              ) || undefined
                            }
                            onChange={currentOption => {
                              form1FieldsByName.year.setValue(
                                currentOption ? currentOption.value : null,
                              );
                              form1FieldsByName.year.setError('');
                            }}
                            isDisabled={form1.submitState === 'submitted'}
                          />
                        </div>
                        <div
                          className={!!form1FieldsByName.year.error ? styles.helperText : ''}
                          style={{
                            visibility: !form1FieldsByName.year.error ? 'hidden' : undefined,
                          }}
                        >
                          {form1FieldsByName.year.error || 'x'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputContainer}>
                      <label
                        className={clsx(
                          styles.label,
                          form1FieldsByName.month.error && styles.error,
                        )}
                        htmlFor="month-input"
                      >
                        Month
                      </label>
                      <div className={styles.selectWrapper}>
                        <div
                          className={clsx(
                            styles.select,
                            form1FieldsByName.month.error && styles.error,
                          )}
                        >
                          <Select
                            inputId="month-input"
                            name="month"
                            placeholder="Jan"
                            styles={customStyles}
                            options={monthSelectOptions}
                            components={{ DropdownIndicator }}
                            value={
                              monthSelectOptions.find(
                                option => option.value === form1FieldsByName.month.value,
                              ) || undefined
                            }
                            onChange={currentOption => {
                              form1FieldsByName.month.setValue(
                                currentOption ? currentOption.value : null,
                              );
                              form1FieldsByName.month.setError('');
                            }}
                            isDisabled={form1.submitState === 'submitted'}
                          />
                        </div>
                        <div
                          className={!!form1FieldsByName.month.error ? styles.helperText : ''}
                          style={{
                            visibility: !form1FieldsByName.month.error ? 'hidden' : undefined,
                          }}
                        >
                          {form1FieldsByName.month.error || 'x'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputContainer}>
                      <label
                        className={clsx(styles.label, form1FieldsByName.day.error && styles.error)}
                        htmlFor="day-input"
                      >
                        Day
                      </label>
                      <div className={styles.selectWrapper}>
                        <div
                          className={clsx(
                            styles.select,
                            form1FieldsByName.day.error && styles.error,
                          )}
                        >
                          <Select
                            inputId="day-input"
                            name="day"
                            placeholder="1"
                            styles={customStyles}
                            options={daysSelectOptions}
                            components={{ DropdownIndicator }}
                            value={
                              daysSelectOptions.find(
                                option => option.value === form1FieldsByName.day.value,
                              ) || undefined
                            }
                            onChange={currentOption => {
                              form1FieldsByName.day.setValue(
                                currentOption ? currentOption.value : null,
                              );
                              form1FieldsByName.day.setError('');
                            }}
                            isDisabled={form1.submitState === 'submitted'}
                          />
                        </div>
                        <div
                          className={!!form1FieldsByName.day.error ? styles.helperText : ''}
                          style={{
                            visibility: !form1FieldsByName.day.error ? 'hidden' : undefined,
                          }}
                        >
                          {form1FieldsByName.day.error || 'x'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <span className={styles.groupTitle}>Birth Time</span>
                  <div className={styles.inputGroupContainer}>
                    <div className={styles.inputContainer}>
                      <label
                        className={clsx(styles.label, form1FieldsByName.hour.error && styles.error)}
                        htmlFor="hour-input"
                      >
                        Hour
                      </label>
                      <div className={styles.selectWrapper}>
                        <div
                          className={clsx(
                            styles.select,
                            form1FieldsByName.hour.error && styles.error,
                          )}
                        >
                          <Select
                            inputId="hour-input"
                            name="hour"
                            placeholder="8"
                            styles={customStyles}
                            options={hoursSelectOptions}
                            components={{ DropdownIndicator }}
                            menuPlacement="top"
                            value={
                              hoursSelectOptions.find(
                                option => option.value === form1FieldsByName.hour.value,
                              ) || undefined
                            }
                            onChange={currentOption => {
                              form1FieldsByName.hour.setValue(
                                currentOption ? currentOption.value : null,
                              );
                              form1FieldsByName.hour.setError('');
                            }}
                            isDisabled={form1.submitState === 'submitted'}
                          />
                        </div>
                        <div
                          className={!!form1FieldsByName.hour.error ? styles.helperText : ''}
                          style={{
                            visibility: !form1FieldsByName.hour.error ? 'hidden' : undefined,
                          }}
                        >
                          {form1FieldsByName.hour.error || 'x'}
                        </div>
                      </div>
                    </div>

                    <div className={styles.inputContainer}>
                      <label
                        className={clsx(
                          styles.label,
                          form1FieldsByName.minutes.error && styles.error,
                        )}
                        htmlFor="minutes-input"
                      >
                        Minutes
                      </label>
                      <div className={styles.selectWrapper}>
                        <div
                          className={clsx(
                            styles.select,
                            form1FieldsByName.minutes.error && styles.error,
                          )}
                        >
                          <Select
                            inputId="minutes-input"
                            name="minutes"
                            placeholder="30"
                            styles={customStyles}
                            options={minutesSelectOptions}
                            components={{ DropdownIndicator }}
                            menuPlacement="top"
                            value={
                              minutesSelectOptions.find(
                                option => option.value === form1FieldsByName.minutes.value,
                              ) || undefined
                            }
                            onChange={currentOption => {
                              form1FieldsByName.minutes.setValue(
                                currentOption ? currentOption.value : null,
                              );
                              form1FieldsByName.minutes.setError('');
                            }}
                            isDisabled={form1.submitState === 'submitted'}
                          />
                        </div>
                        <div
                          className={!!form1FieldsByName.minutes.error ? styles.helperText : ''}
                          style={{
                            visibility: !form1FieldsByName.minutes.error ? 'hidden' : undefined,
                          }}
                        >
                          {form1FieldsByName.minutes.error || 'x'}
                        </div>
                      </div>
                    </div>

                    <div className={styles.inputContainer}>
                      <div className={styles.timePeriod}>
                        <label
                          className={clsx(
                            styles.label,
                            form1FieldsByName.timePeriod.error && styles.error,
                          )}
                          htmlFor="time-input"
                        >
                          Time
                        </label>
                        <div className={styles.selectWrapper}>
                          <div
                            className={clsx(
                              styles.select,
                              form1FieldsByName.timePeriod.error && styles.error,
                            )}
                          >
                            <Select
                              inputId="time-input"
                              name="timePeriod"
                              placeholder="AM"
                              styles={customStyles}
                              options={timePeriodSelectOptions}
                              components={{ DropdownIndicator }}
                              menuPlacement="top"
                              value={
                                timePeriodSelectOptions.find(
                                  option => option.value === form1FieldsByName.timePeriod.value,
                                ) || undefined
                              }
                              onChange={currentOption => {
                                form1FieldsByName.timePeriod.setValue(
                                  currentOption ? currentOption.value : '',
                                );
                                form1FieldsByName.timePeriod.setError('');
                              }}
                              isDisabled={form1.submitState === 'submitted'}
                            />
                          </div>
                          <div
                            className={
                              !!form1FieldsByName.timePeriod.error ? styles.helperText : ''
                            }
                            style={{
                              visibility: !form1FieldsByName.timePeriod.error
                                ? 'hidden'
                                : undefined,
                            }}
                          >
                            {form1FieldsByName.timePeriod.error || 'x'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label className={styles.groupTitle} htmlFor="cityOfBirth-input">
                    City of Birth
                  </label>
                  <div className={styles.cityOfBirth}>
                    <img src={mapPin} alt="" className={styles.cityOfBirthIcon} />
                    <PlacesAutocomplete
                      searchOptions={{
                        types: ['locality', 'sublocality', 'neighborhood'],
                      }}
                      debounce={300}
                      value={form1FieldsByName.cityOfBirth.value}
                      onChange={address => {
                        form1FieldsByName.cityOfBirth.setValue(address);
                        form1FieldsByName.cityOfBirth.setError('');
                        setCityOfBirthCoords(null);
                      }}
                      onSelect={(address, placeId) => {
                        form1FieldsByName.cityOfBirth.setValue(address);
                        if (placeId) {
                          geocodeByPlaceId(placeId)
                            .then(results => getLatLng(results[0]))
                            .then(latLng => {
                              setCityOfBirthCoords(latLng);
                              form1FieldsByName.cityOfBirth.setError('');
                            })
                            .catch(error => {
                              console.error(error);
                              Sentry.captureException(error);
                              form1FieldsByName.cityOfBirth.setError(
                                'An unknown error has occurred. Please try again later',
                              );
                            });
                        }
                      }}
                      onError={(errorStatus, clearSuggestions) => {
                        if (errorStatus !== 'ZERO_RESULTS') {
                          console.error(`Got unknown errorStatus: ${errorStatus}`);
                          Sentry.captureException(
                            new Error(`Got unknown errorStatus: ${errorStatus}`),
                          );
                          form1FieldsByName.cityOfBirth.setError(
                            'An unknown error has occurred. Please try again later',
                          );
                          clearSuggestions();
                        }
                      }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className={styles.cityOfBirthInnerContainer}>
                          <InputField
                            data-lpignore="true"
                            dontUnpackValue
                            className={clsx(
                              styles.input,
                              styles.cityOfBirthInput,
                              form1FieldsByName.cityOfBirth.error && styles.error,
                            )}
                            type="text"
                            id="cityOfBirth-input"
                            name="cityOfBirth"
                            placeholder="London, UK"
                            helperTextClass={styles.helperText}
                            {...form1.getFieldProps(form1FieldsByName.cityOfBirth)}
                            {...getInputProps()}
                            onBlur={() => {
                              getInputProps().onBlur();
                              form1.getFieldProps(form1FieldsByName.cityOfBirth).onBlur();
                            }}
                          />

                          <div
                            className={clsx(
                              styles.cityOfBirthSuggestionsContainer,
                              loading && styles.loading,
                            )}
                          >
                            {loading && (
                              <div
                                className={clsx(
                                  styles.helperTextLoading,
                                  form1FieldsByName.cityOfBirth.error && styles.error,
                                )}
                              >
                                Loading...
                              </div>
                            )}
                            {suggestions.map(suggestion => {
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion)}
                                  style={customStyles.option(
                                    {},
                                    {
                                      isFocused: suggestion.active,
                                    },
                                  )}
                                  key={suggestion.placeId}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                {form1.renderSubmitButton({
                  labels: {
                    ready: 'Find out',
                    submitting: 'Finding your star...',
                    submitted: 'Finding your star...',
                  },
                  btnClasses: {
                    common: styles.submitButton,
                    ready: styles.formReady,
                    submitting: styles.formSubmitting,
                    submitted: styles.formSubmitted,
                  },
                })}
                {form1.renderFormMessage({
                  styles: {
                    formMessage: styles.formMessage,
                    formMessageSuccess: styles.formMessageSuccess,
                    formMessageError: styles.formMessageError,
                  },
                })}
              </form>
            </div>
          </>
        )}
        {currentScreen === 'result' && (
          <>
            <div className={styles.signContainer}>
              <span className={styles.signName}>{vedicRisingSigns[nakshatra].name} </span>
              <p className={styles.signIntroPhrase}>"{vedicRisingSigns[nakshatra].introPhrase}"</p>
              <p className={styles.signText}>{vedicRisingSigns[nakshatra].text}</p>
            </div>
            <div className={styles.knowMoreContainer}>
              <h3 className={styles.knowMoreTitle}>{resultScreen.knowMoreTitle}</h3>
              <p className={styles.knowMoreText}>{resultScreen.knowMoreText}</p>
              <div className={styles.formContainer}>
                <form name="contact">
                  <div className={clsx(styles.inputContainer, styles.nameInput)}>
                    <label
                      className={clsx(styles.label, form2FieldsByName.name.error && styles.error)}
                      htmlFor="name-input"
                    >
                      Name
                    </label>
                    <InputField
                      className={clsx(
                        styles.input,
                        styles.nameInput,
                        form2FieldsByName.name.error && styles.error,
                      )}
                      type="text"
                      id="name-input"
                      name="name"
                      placeholder="Anne Marie"
                      helperTextClass={styles.helperText}
                      {...form2.getFieldProps(form2FieldsByName.name)}
                    />
                  </div>
                  <div className={clsx(styles.inputContainer, styles.emailInput)}>
                    <label
                      className={clsx(styles.label, form2FieldsByName.email.error && styles.error)}
                      htmlFor="email-input"
                    >
                      Email
                    </label>
                    <InputField
                      className={clsx(
                        styles.input,
                        styles.emailInput,
                        form2FieldsByName.email.error && styles.error,
                      )}
                      type="text"
                      id="email-input"
                      name="email"
                      placeholder="example@service.com"
                      helperTextClass={styles.helperText}
                      {...form2.getFieldProps(form2FieldsByName.email)}
                    />
                  </div>
                  {form2.renderSubmitButton({
                    labels: {
                      ready: 'Send',
                      submitting: 'Sending...',
                      submitted: 'Sending...',
                    },
                    btnClasses: {
                      common: clsx(
                        styles.submitButton,
                        currentScreen === 'result' && styles.greenButton,
                      ),
                      ready: styles.formReady,
                      submitting: styles.formSubmitting,
                      submitted: styles.formSubmitted,
                    },
                  })}
                  {form2.renderFormMessage({
                    styles: {
                      formMessage: styles.formMessage,
                      formMessageSuccess: styles.formMessageSuccess,
                      formMessageError: clsx(styles.formMessageError, styles.purpleBackground),
                    },
                  })}
                </form>
              </div>
            </div>
          </>
        )}
        {currentScreen === 'share' && (
          <>
            <div className={styles.reportText}>
              <BlockContent
                className={styles.text}
                blocks={shareScreen._rawReportSectionText}
                renderContainerOnSingleChild
                serializers={serializers}
              />
            </div>
            <div className={styles.shareContainer}>
              <h3 className={styles.shareTitle}>{shareScreen.shareSectionTitle}</h3>
              <p className={styles.shareText}>{shareScreen.shareSectionText}</p>
              <button className={styles.shareButton} onClick={openShareModal}>
                <span>Share</span>
              </button>
              {isShareCardVisible && (
                <ModalOverlay
                  ref={shareModalRef}
                  onClose={closeShareModal}
                  className={clsx(
                    styles.shareModal,
                    shareCardAnimation === 'exit' && styles.closing,
                  )}
                  childrenClassName={clsx(styles.shareCard)}
                >
                  <ShareCard onClose={closeShareModal} />
                </ModalOverlay>
              )}
            </div>
          </>
        )}
      </Portal>
    </div>
  );
};

export default PortalForm;
