import { wait } from './utils/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class ApiError extends Error {
  constructor(message: string, readonly body: Record<string, string>) {
    super(message);
  }
}

export async function calculateNakshatraAndPada(
  year: number,
  month: number,
  day: number,
  hour: number,
  minutes: number,
  timePeriod: string,
  cityOfBirthCoords: { lat: number; lng: number },
): Promise<{ nakshatra: string; pada: number }> {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const data = {
    year,
    month,
    day,
    hour,
    minutes,
    timePeriod,
    cityOfBirthCoords,
  };

  const respCalcNakshatra = await fetch('/.netlify/functions/calculate-nakshatra', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (respCalcNakshatra.status !== 200) {
    if (respCalcNakshatra.status === 400) {
      const errorBody = await respCalcNakshatra.json();
      throw new ApiError(
        'Got response from calculate-nakshatra with status code ' + respCalcNakshatra.status,
        errorBody,
      );
    }
    throw new Error(
      'Got response from calculate-nakshatra with status code ' + respCalcNakshatra.status,
    );
  }
  return respCalcNakshatra.json();
}

export async function addContactToKajabi(
  name: string,
  email: string,
  year: number,
  month: number,
  day: number,
  hour: number,
  minutes: number,
  timePeriod: string,
  cityOfBirth: string,
  cityOfBirthCoords: { lat: number; lng: number },
  nakshatra: string,
) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const data = {
    name,
    email,
    year,
    month,
    day,
    hour: timePeriod === 'am' ? hour : hour + 12,
    minute: minutes,
    location: cityOfBirth,
    locationCoords: `${cityOfBirthCoords.lat},${cityOfBirthCoords.lng}`,
    nakshatra,
  };

  const respKajabi = await fetch('/.netlify/functions/add-contact-to-kajabi', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (respKajabi.status !== 200) {
    throw new Error(
      'Got response from add-contact-to-kajabi with status code ' + respKajabi.status,
    );
  }
}

// export async function sendNetlifyEmail(
//   email: string,
//   year: number,
//   month: number,
//   day: number,
//   hour: number,
//   minutes: number,
//   timePeriod: string,
//   cityOfBirth: string,
//   cityOfBirthCoords: { lat: number; lng: number },
//   nakshatra: string,
// ) {
//   if (process.env.NODE_ENV === 'development') {
//     // Code to test form states (should be commented)
//     await wait(1000); // Case loading
//     // throw new Error('Got response with status code 400'); // Case unknown error
//     // throw new Error('Failed to fetch'); // Case network error
//     return true; // Case success
//   }

//   const data = {
//     email,
//     year,
//     month,
//     day,
//     hour,
//     minutes,
//     timePeriod,
//     cityOfBirth,
//     cityOfBirthCoords,
//     nakshatra,
//   };

//   const respNetlifyForm = await fetch('/', {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//     body: encodeFormData({
//       'form-name': 'contact',
//       cityOfBirth: JSON.stringify(data, null, 2),
//     }),
//   });
//   if (respNetlifyForm.status !== 200) {
//     throw new Error('Got response from netlify form with status code ' + respNetlifyForm.status);
//   }
// }
