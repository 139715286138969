import React, { useEffect, useRef, useState } from 'react';
import { clsx } from '../../utils/utils';
import Swan from '../../images/Swan.inline.svg';

import * as styles from './Portal.module.scss';

export interface PortalProps {
  title: string;
  children: any;
  className?: string;
  constantHeight?: boolean;
  isPurple?: boolean;
}

const Portal = ({
  title,
  children,
  className,
  constantHeight,
  isPurple,
}: PortalProps): React.ReactElement => {
  const [minInsideContainerHeight, setMinInsideContainerHeight] = useState<number | null>(null);
  const insideContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Warning: this code doesn't allow for the portal to shrink, only grow
    function updateMinInsideContainerHeight() {
      if (constantHeight && insideContainerRef.current) {
        setMinInsideContainerHeight(insideContainerRef.current.getBoundingClientRect().height);
      }
    }
    updateMinInsideContainerHeight();
    window.addEventListener('resize', updateMinInsideContainerHeight);
  }, []);

  return (
    <div className={clsx(className, styles.container, isPurple && styles.purple)}>
      <div
        ref={insideContainerRef}
        className={styles.insideContainer}
        style={{ minHeight: (constantHeight && minInsideContainerHeight) || undefined }}
      >
        <div className={styles.swanContainer}>
          <Swan className={styles.swan} />
        </div>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};

export default Portal;
